$(function () {

	$('#lead_script').on('click','#change_postcode_button',function(e){
		e.preventDefault();
		$('.postcode-lookup-section').removeClass('hide');
		$('.uw-address-select-section').addClass('hide');
		$('.uw-manual-address-section').addClass('hide');
		if($('.secondary-address-selection-needed').length){
			$('#secondary_address_confirmation_button').addClass('hide disabled');
			// we need to clear some other divs.
			$('.fancy-section-loading-placeholder').addClass('hide');
			$('.tariff-information-dynamic-text').html("");
			$('.tariff-pitch-dynamic-text-section').addClass('hide');
			$('.customer-information-dynamic-text').html("");
			$('.customer-information-section').addClass('hide');
			$('.address-confirmation-dynamic-text').html("");
			$('.address-confirmation-section').addClass('hide');
			$('.direct-debit-information-dynamic-text').html("");
			$('.direct-debit-information-section').addClass('hide');
			$('.go-to-checkout-section').addClass('hide');
		}
	});

	$('#lead_script').on('click','#show_manual_address_button',function(e){
		e.preventDefault();
		$('.uw-address-select-section').addClass('hide');
		$('.uw-manual-address-section').removeClass('hide');
		if($('.secondary-address-selection-needed').length){
			$('#secondary_address_confirmation_button').removeClass('hide disabled');
			// we need to clear some other divs.
			$('.fancy-section-loading-placeholder').addClass('hide');
			$('.tariff-information-dynamic-text').html("");
			$('.tariff-pitch-dynamic-text-section').addClass('hide');
			$('.customer-information-dynamic-text').html("");
			$('.customer-information-section').addClass('hide');
			$('.address-confirmation-dynamic-text').html("");
			$('.address-confirmation-section').addClass('hide');
			$('.direct-debit-information-dynamic-text').html("");
			$('.direct-debit-information-section').addClass('hide');
			$('.go-to-checkout-section').addClass('hide');
		}
	});

	$('#lead_script').on('change','.secondary-address-selection-needed #address_select',function(){
		if($('.secondary-address-selection-needed').length){
			$('#secondary_address_confirmation_button').removeClass('disabled').trigger('click');
			// we need to clear some other divs.
			$('.fancy-section-loading-placeholder').removeClass('hide');
			$('.tariff-information-dynamic-text').html("");
			$('.tariff-pitch-dynamic-text-section').addClass('hide');
			$('.customer-information-dynamic-text').html("");
			$('.customer-information-section').addClass('hide');
			$('.address-confirmation-dynamic-text').html("");
			$('.address-confirmation-section').addClass('hide');
			$('.direct-debit-information-dynamic-text').html("");
			$('.direct-debit-information-section').addClass('hide');
			$('.go-to-checkout-section').addClass('hide');
		}
	});


	$('#lead_script').on('click','.postcode-lookup-section #search_address',function(){
		var postcode = $('.postcode-lookup-section #postcode_input').val();
		makeRequest('/quote/utility-warehouse/postcode-search?postcode='+encodeURIComponent(postcode),{}, function(response){
			if(isset(response.success) && response.success){
				$('.uw-address-select-section').html(response.html);
				$('.postcode-lookup-section').addClass('hide');
				$('.uw-address-select-section').removeClass('hide');
				if($('.secondary-address-selection-needed').length){
					$('#secondary_address_confirmation_button').removeClass('hide disabled');
				}
			}
			else{
				error_message(response.error);
			}
			// script.handleStep($(that).data('success-step'));
		},'GET');
	});

	$('#lead_script').on('change','input[name="paper_billing"]',function(){
		if($(this).is(':checked')){
			$('.billing-warning').removeClass('hide');
		}
		else{
			$('.billing-warning').addClass('hide');
		}
	});


	$('#lead_script').on('click', '#utility_warehouse_energy_mobile_selection_next', function(e) {
		showLoader();
		e.preventDefault();
		var next_step = $(this).data('success-step');

		var quote_id = $('#lead_script').data('quoteid');
		
		// Collect data from all active sim-detail divs
		var simDetails = $('.selected-sim-option').not('.hidden').find('.sim-detail').not('.hidden');
		var simData = [];

		simDetails.each(function() {
			var simDetail = $(this);
			var simInfo = {
				tariff: simDetail.data('tariff_id'), 
				budget_control: simDetail.find('input[name="budget_control"]').val(),
				keep_existing_number: simDetail.find('input[name="keep_existing_number"]').val(),
				pac_code: simDetail.find('input[name="pac_code"]').val(),
				cancel_existing_contract: simDetail.find('input[name="cancel_existing_contract"]').val(),
				stac_code: simDetail.find('input[name="stac_code"]').val(),
				existing_number: simDetail.find('input[name="existing_number"]').val()
			};
			simData.push(simInfo);
		});

		var data = {
			simDetails: simData,
			quote_id :quote_id
		};

		makeRequest('/quote/utility-warehouse/set-mobile-tariff-selection', data, function(response) {
			if (response.success) {
				lead_script.handleStep(next_step);
				hideLoader();
			} else {
				error_message(response.error);
				hideLoader();
			}
		});
	});


	

	$('#lead_script').on('click','#utility_warehouse_energy_welcome_step_next',function(e){
		showLoader();
		var quote_id = $('#lead_script').data('quoteid');
		var address_id = $('#lead_script .uw-address-select-section #address_select :selected').val();
		var home_owner = $('.home-owner-status-section input[name="home_owner"]:checked').val();
		var data = {
			quote_id : quote_id,
			address_id : address_id,
			home_owner :home_owner,
		};
		// If the select address section is not visible, it's a manual address.
		if($('.uw-address-select-section').hasClass('hide') && !$('.uw-manual-address-section').hasClass('hide')) {
			var isValid = true;
			var formData = {
				"flatNumber": $('#flatNumber').val().trim(),
				"buildingName": $('#buildingName').val().trim(),
				"street": $('#street').val().trim(),
				"town": $('#cityTown').val().trim(),
				"buildingNumber": $('#buildingNumber').val().trim(),
				"postcode": $('#manual_postcode').val().trim(),
				"addressNotListed": true,
			};

			// Check each required field and display an error if it's empty.
			$('.uw-manual-address-section .row').find('input[required]').each(function() {
				var $input = $(this);
				if ($input.val().trim() === '') {
					$('#error_' + $input.attr('id')).removeClass('hide').addClass('shake'); // Show error message
					isValid = false;
				} else {
					$('#error_' + $input.attr('id')).addClass('hide').removeClass('shake'); // Hide error message
				}
			});

			// If not all required fields are valid, stop and show error.
			if (!isValid) {
				hideLoader();
				error_message('All required fields must be filled out.');
				return false;
			}

			// Extend the existing data object with the form data.
			$.extend(data, formData);
		} else if(address_id.length < 5) {
			error_message('Invalid address selected');
			return false;
		}
		

		if(!isset(home_owner)){
			hideLoader();
			error_message('please select home owner status');
			return false;
		}
		
		var next_step = $(this).data('success-step');

		makeRequest('/quote/utility-warehouse/start-quote',data, function(response){
			if(isset(response.success) && response.success){
				lead_script.handleStep(next_step);
				hideLoader();
			}
			else{
				error_message(response.error);
				hideLoader();
			}
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('#lead_script').on('click','#utility_warehouse_energy_service_selection_next',function(e){
		showLoader();
		var quote_id = $('#lead_script').data('quoteid');
		var next_step = $(this).data('success-step');
		// Gather selected services
		var selectedServicesArray = Object.keys(selectedServices).filter(key => selectedServices[key]);

		// Check if at least one service is selected
		if (selectedServicesArray.length === 0) {
			hideLoader();
			error_message('You must select at least one service.');
			return;
		}

		// Prepare data to be sent
		var data = {
			'quote_id': quote_id,
			'services': selectedServicesArray
		};

		makeRequest('/quote/utility-warehouse/set-requested-services',data, function(response){
			if(isset(response.success) && response.success){
				var current_step = lead_script.step;
				var target_step = current_step.split('.').slice(0, 1).join('') + '.' + response.step;
				lead_script.handleStep(target_step);
				hideLoader();
			}
			else{
				error_message(response.error);
				hideLoader();
			}
		});
	});

	$('#lead_script').on('click','.show-broadband-edit-options',function(e){
		e.preventDefault();
		$('.broadband-and-phone-options').removeClass('hide');
		$(this).addClass('hide');
	});

	$('#lead_script').on('click','.show-mobile-edit-options',function(e){
		e.preventDefault();
		$('.mobile-tariffs').removeClass('hide');
		$(this).addClass('hide');
	});
	

	$('#lead_script').on('click','#utility_warehouse_energy_broadband_package_select_next',function(e){
		showLoader();
		var quote_id = $('#lead_script').data('quoteid');
		var next_step = $(this).data('success-step');

		// Extract values from the form
		var broadbandPackageId = $('#broadband_package_id').val();
		var equipmentPackageId = $('#equipment_package_id').val();

		if(broadbandPackageId.toLowerCase().indexOf('fibre') !== -1){
			// can't have these things with fibre
			var landlineNumber = '';
			var isVirgin = false;
			var callPackageId = 'no_package';
		}
		else{
			var callPackageId = $('#call_package_id').val();
			var landlineNumber = $('#telephone_number_to_port').val();
			var isVirgin = $('#virgin_media_phone_line').val() === 'yes';
		}
		
		
		var eeroDevices = $('#eero_devices_required').val();

		// Retrieve the hidden JSON data
		var broadbandTariffs = JSON.parse($('#broadband_tariff_data').attr('data-broadband_tariff_data'));
		var callPackages = JSON.parse($('#call_packages_data').attr('data-call_packages_data'));

		// Find the selected broadband and equipment package details
		var selectedBroadband = broadbandTariffs.find(tariff => tariff.meta.id === broadbandPackageId);
		var selectedEquipment = selectedBroadband ? selectedBroadband.equipmentPackage.find(equip => equip.id === equipmentPackageId) : null;

		// Construct data object
		var data = {
			'quote_id': quote_id,
			'tariff': selectedBroadband ? selectedBroadband.meta.id : null,
			'equipment': selectedEquipment ? selectedEquipment.name : null,
			'callPackage': callPackageId,
			'landlineNumber': landlineNumber,
			'isVirgin': isVirgin,
			'eeroDevices': parseInt(eeroDevices)
		};

		// Make the request
		makeRequest('/quote/utility-warehouse/set-broadband-package', data, function(response){
			if(response.success){
				lead_script.handleStep(next_step);
				hideLoader();
			}
			else{
				error_message(response.error);
				hideLoader();
			}
		});
	});



	$('#lead_script').on('input', 'input[name="virgin_media_phone_line"]', function() {
		var isVirginMediaLine = $(this).val();
		$('#virgin_media_phone_line').val(isVirginMediaLine);
		updateSummary(); // Assuming you have a function to update the summary
	});

	function updateSummary() {
		// Retrieve values from the form
		var broadbandId = $('#broadband_package_id').val();
		var equipmentId = $('#equipment_package_id').val();
		var callPackageId = $('#call_package_id').val();
		var eeroDevices = parseInt($('#eero_devices_required').val(), 10);
		var fullPackagePrice  = 0;
		
		// Parse JSON data from hidden divs
		var broadbandData = JSON.parse($('#broadband_tariff_data').attr('data-broadband_tariff_data'));
		var callPackageData = JSON.parse($('#call_packages_data').attr('data-call_packages_data'));

		// Initialize summary variables
		var broadbandName = '', broadbandPrice = 0, promoText = '',
			equipmentName = '', equipmentPrice = 0, deliveryCost = 0,
			callPackageName = '', callPackagePrice = 0, monthlyTotal = 0;
		
		// Find and calculate broadband package details
		if(broadbandId) {
			var selectedBroadband = broadbandData.find(tariff => tariff.meta.id === broadbandId);
			broadbandName = selectedBroadband.meta.name;
			if(selectedBroadband.contract.promo && isset(selectedBroadband.contract.discountLineRental.value)){
				broadbandPrice = selectedBroadband.contract.promo.monthlyPrice.value+selectedBroadband.contract.discountLineRental.value;
			}
			else if(selectedBroadband.contract.promo){
				broadbandPrice = selectedBroadband.contract.promo.monthlyPrice.value + selectedBroadband.contract.lineRental.value;
			}
			// else if(isset(selectedBroadband.contract.discountLineRental.value) && isset(selectedBroadband.contract.discountMonthlyPrice.value)){
			// 	broadbandPrice = selectedBroadband.contract.discountMonthlyPrice.value + selectedBroadband.contract.discountLineRental.value;
			// }
			else{
				broadbandPrice = selectedBroadband.contract.monthlyPrice.value + selectedBroadband.contract.lineRental.value;
			}
			
			if(isset(selectedBroadband.contract.discountMonthlyPrice.value) && isset(selectedBroadband.contract.discountLineRental.value) && selectedBroadband.contract.promo){
				fullPackagePrice += selectedBroadband.contract.discountMonthlyPrice.value+selectedBroadband.contract.discountLineRental.value;

			}
			else{
				fullPackagePrice += selectedBroadband.contract.monthlyPrice.value+selectedBroadband.contract.lineRental.value;
			}
			// Convert to monthly price
			broadbandPrice /= Math.pow(10, selectedBroadband.contract.monthlyPrice.exponent);
			// Find and calculate equipment package details
			if(equipmentId) {
				var selectedEquipment = selectedBroadband.equipmentPackage.find(equip => equip.id === equipmentId);
				equipmentName = selectedEquipment.name;
				
				deliveryCost = selectedEquipment.oneOffCharge.value;
				
				// If Whole Home Wi-Fi is selected, calculate eero devices cost
				if(equipmentId === 'WHOLE_HOME') {
					var eeroDevice = selectedEquipment.broadbandEquipment.find(equip => equip.id === 'EERO');
					var eeroPrice = eeroDevice.monthlyCharge.value / Math.pow(10, eeroDevice.monthlyCharge.exponent);
					// Multiply by the number of devices required
					equipmentPrice += eeroPrice * eeroDevices;
				}
			}
			var lineRentalPrice = isset(selectedBroadband.contract.discountLineRental.value) ? selectedBroadband.contract.discountLineRental.value : selectedBroadband.contract.lineRental.value;
			lineRentalPrice /= Math.pow(10,2);
			var lineRentalText = 'Price includes £'+lineRentalPrice.toFixed(2)+' line rental';
			if(lineRentalPrice > 0){
				$('.summary .line-rental-notice').text(lineRentalText);
				$('.summary .line-rental-notice').removeClass('hide');
			}
			else{
				$('.summary .line-rental-notice').text('');
				$('.summary .line-rental-notice').addClass('hide');
			}
		}
		var phoneNumber = $('#telephone_number_to_port').val();
		var virginMediaCustomer = $('#virgin_media_phone_line').val();
		var regexUkPhoneNumber = /^(?:0\d{3,4}\d{6,7})$/;
		var lineActivationRequired = true;
		var lineActivationPrice = 0;
		if ((regexUkPhoneNumber.test(phoneNumber) && virginMediaCustomer != 'yes') || broadbandId.toLowerCase().indexOf('fibre') !== -1) {
			lineActivationRequired = false;
		}

		if (lineActivationRequired) {
			$('.line-activation-row').removeClass('hide');
			lineActivationPrice = 1;
			$('.line-activation-price').text('£'+lineActivationPrice);
		} else {
			$('.line-activation-row').addClass('hide');
			lineActivationPrice = 0;
			$('.line-activation-price').text('£'+lineActivationPrice);
		}
		
		if(broadbandId.toLowerCase().indexOf('fibre') !== -1){
			// hide line activation and call package rows
			$('.call-package-row').addClass('hide');
		}
		else{
			$('.call-package-row').removeClass('hide');
			// Find and calculate call package details
			if(callPackageId) {
				if(callPackageId == 'no_package'){
					callPackageName = 'No Call Package';
					callPackagePrice = 0;
				}
				else {
					var selectedCallPackage = callPackageData.find(pkg => pkg.id == callPackageId);
					callPackageName = selectedCallPackage.name;
					callPackagePrice = selectedCallPackage.cost.value;
					fullPackagePrice += selectedCallPackage.cost.value;
					// Convert to monthly price
					callPackagePrice /= Math.pow(10, selectedCallPackage.cost.exponent);
				}
			}
			else {
				callPackageName = 'No Call Package';
				callPackagePrice = 0;
			}
		}
		fullPackagePrice /= Math.pow(10, 2);
		var promoText = selectedBroadband.contract.promo 
						? 'After '+selectedBroadband.contract.promo['length']+' months it\'s £'+fullPackagePrice 
						: '';
		// Calculate total monthly price
		monthlyTotal = broadbandPrice + equipmentPrice + callPackagePrice + lineActivationPrice;

		deliveryCostText = '';
		deliveryCost /= Math.pow(10,2);
		if(deliveryCost > 0){
			var deliveryCostText = 'A £'+deliveryCost.toFixed(2)+' delivery charge will be added to your first bill.'
		}

		// Update the summary section with the calculated details
		$('.summary .broadband-package-name').text(broadbandName);
		$('.summary .broadband-package-price').text(`£${broadbandPrice.toFixed(2)}`);
		$('.summary .equipment-option-name').text(equipmentName);
		$('.summary .equipment-option-price').text(`${equipmentPrice.toFixed(2) > 0 ? '£'+equipmentPrice.toFixed(2) : 'Free' }`);
		$('.summary .call-package-name').text(callPackageName);
		$('.summary .call-package-price').text(`£${callPackagePrice.toFixed(2)}`);
		$('.summary .monthly-total-price').text(`£${monthlyTotal.toFixed(2)}`);
		$('.summary .promo-text').text(promoText);
		$('.summary .delivery-cost-notice').text(deliveryCostText);
		
	}

		// Remember to call updateSummary() whenever a selection changes that affects the summary.

	function updateEeroDeviceDisplay(count) {
        $('.number-select .number').text(count); // Update the displayed number
        $('#eero_devices_required').val(count); // Update the hidden input value
        // Call the function to update the summary as the number of eero devices has changed
        updateSummary();
    }

	$('#lead_script').on('click', '.equipment-option', function() {
		$(this).find('.select-equipment').trigger('click');
	});

	$('#lead_script').on('click', '.call-package', function(e) {
		e.stopPropagation();
		var radio = $(this).find('input[name="call_package"]');
    	radio.prop('checked', true).trigger('change');
	});

	var eeroDeviceCount = $('#eero_devices_required').val() >= 2 && $('#eero_devices_required').val() <= 5 ? $('#eero_devices_required').val() : 2;

    // Event listener for the minus button
    $('#lead_script').on('click', '.minus', function() {
        if (eeroDeviceCount > 2) { // Ensure the count never goes below 2
            eeroDeviceCount--;
            updateEeroDeviceDisplay(eeroDeviceCount);
        }
    });

    // Event listener for the plus button
    $('#lead_script').on('click', '.plus', function() {
        if (eeroDeviceCount < 5) { // Ensure the count never goes above 5
            eeroDeviceCount++;
            updateEeroDeviceDisplay(eeroDeviceCount);
        }
    });

	$('#lead_script').on('click', ' .broadband-and-phone-options .tariff-option', function() {
		// Get the selected tariff ID
		var tariffId = $(this).data('tariff-id');
		$('#broadband_package_id').val(tariffId);
		if(tariffId.toLowerCase().indexOf('fibre') !== -1){
			$('.landline-warning').removeClass('hide');
			$('.landline-warning').addClass('shake');
			$('.landline-options ').addClass('hide');
		}
		else{
			$('.landline-warning').addClass('hide');
			$('.landline-warning').removeClass('shake');
			$('.landline-options ').removeClass('hide');
		}

		// Deselect all tariffs and select the clicked one
		$('.tariff-option').removeClass('tariff-selected');
		$('.select-tariff').removeClass('tariff-selected').text('Select');
		$(this).addClass('tariff-selected');
		$(this).find('.select-tariff').addClass('tariff-selected').text('Selected');

		// Hide all equipment selections
		$('.equipment-selection').addClass('hidden');

		// Retrieve the existing equipment selection from the form
		var existingEquipmentSelection = $('#equipment_package_id').val();

		// Check if the existing selected equipment is available in the new tariff
		var equipmentExistsInNewTariff = $('#equipment-for-' + tariffId + ' .equipment-option[data-equipment-id="' + existingEquipmentSelection + '"]').length > 0;

		// If the existing equipment selection exists in the new tariff, reselect it
		if (existingEquipmentSelection && equipmentExistsInNewTariff) {
			// Find and select the equipment option in the new tariff
			var equipmentOption = $('#equipment-for-' + tariffId + ' .equipment-option[data-equipment-id="' + existingEquipmentSelection + '"]');
			equipmentOption.addClass('equipment-selected');
			equipmentOption.find('.select-equipment').addClass('equipment-selected').text('Selected');
			// If it's a whole home equipment, show the custom eero section
			if (existingEquipmentSelection === 'WHOLE_HOME') {
				$('#customise-eero').removeClass('hidden');
			}
		} else {
			// If the equipment doesn't exist in the new tariff, clear the equipment selection
			$('#equipment_package_id').val('');
		}

		// Show the equipment selection for the chosen tariff
		$('#equipment-for-' + tariffId).removeClass('hidden');
		$('#equipment-for-' + tariffId).scrollTop(0);

		// Update the summary to reflect the new selections
		updateSummary();
	});

	$('#lead_script').on('click','.select-equipment', function(e) {
		e.stopPropagation(); 
		var equipmentId = $(this).closest('.equipment-option').data('equipment-id');
		$('#equipment_package_id').val(equipmentId);

		$('.equipment-option').removeClass('equipment-selected');
		$('.select-equipment').removeClass('equipment-selected').text('Select');

		$(this).addClass('equipment-selected');
		$(this).find('.select-equipment').addClass('equipment-selected').text('Selected')
		
		if(equipmentId === 'WHOLE_HOME') {
			$('#customise-eero').removeClass('hidden');
			updateEeroDeviceDisplay(eeroDeviceCount);
		} else {
			$('#customise-eero').addClass('hidden');
		}
		var broadband_id = $('#broadband_package_id').val();
		$('#utility_warehouse_energy_broadband_package_select_next').removeClass('hide');
		if(broadband_id.toLowerCase().indexOf('fibre') === -1){
			$('.landline-options').removeClass('hide');
			$('.landline-options').scrollTop = 0;
		}
		else{
			$('.landline-options').addClass('hide');
		}
		
		// add some logic here to update the summary based on what we have selected already
		// this should be a seperate function as we will call it from multiple places as things change
		$('.summary').removeClass('hidden');
		updateSummary();
	});

	$('#lead_script').on('input', '#landline-number', function() {
		var phoneNumber = $(this).val().replace(/\s+/g, '');;
	    
		var regexUkPhoneNumber = /^(?:0\d{3,4}\d{6,7})$/;
		$('#landline-number').val(phoneNumber);

		if (regexUkPhoneNumber.test(phoneNumber)) {
			$('.virgin-media-customer').show();
		} else {
			$('.virgin-media-customer').hide();
			$('#virgin_no').prop('checked', true); // Reset to "No" when hidden
		}
	});



	$('#lead_script').on('change','input[name="call_package"]', function(e) {
		var callPackageId = $(this).val();
		$('#call_package_id').val(callPackageId);
		updateSummary();
	});

	$('#lead_script').on('input','#landline-number', function() {
		const landlineNumber = $(this).val();
		$('#telephone_number_to_port').val(landlineNumber);
		updateSummary();
	});

	// When the number of eero devices is selected
	$('#lead_script').on('change','.eero-devices-selection .number-select .number', function() {
		const eeroDevices = $(this).text();
		$('#eero_devices_required').val(eeroDevices);
		updateSummary();
	});


	var selectedServices = {};

	// Function to initialize data and bind events
	window.initializeServiceSelection = function() {
		var serviceData = JSON.parse($('.service-selection #service_data').attr('data-services'));
		
		$('.service-item').on('click', function() {
			if ($(this).hasClass('disabled')) {
				// Ignore click events on disabled items
				return;
			}
			var serviceKey = $(this).find('.select-service').data('service');

			// Create a copy of selectedServices to simulate the change
			var newSelectedServices = Object.assign({}, selectedServices);

			// Simulate adding/removing this service from the selection
			if (newSelectedServices.hasOwnProperty(serviceKey)) {
				delete newSelectedServices[serviceKey];
			} else {
				newSelectedServices[serviceKey] = true;
			}

			// Check if the new selection forms a restricted combination
			if (isSelectionRestricted(Object.keys(newSelectedServices))) {
				// If the new selection is restricted, reset all selections
				$('.service-item').removeClass('service-selected').find('.select-service').removeClass('service-selected').text('Select');
				selectedServices = {};
			} else {
				// If the new selection is not restricted, apply the change
				selectedServices = newSelectedServices;
				$(this).toggleClass('service-selected');
				$(this).find('.select-service').toggleClass('service-selected').text($(this).hasClass('service-selected') ? 'Selected' : 'Select');
			}

			updateDisplay(serviceData);
		});
		
		$('.select-service').on('click',function(e) {
			e.stopPropagation();
			$(this).closest('.service-item').trigger('click');
		});
		initializeDefaultServices(function(serviceData){
			updateDisplay(serviceData); // Initial display update
		});
	}

	function initializeDefaultServices(callback) {
		var defaultServices = JSON.parse($('#default_services_data').attr('data-default_services'));
		var serviceData = JSON.parse($('.service-selection #service_data').attr('data-services'));
		defaultServices.forEach(service => {
			var serviceItem = $(`.service-item[data-service-key="${service}"]`);
			serviceItem.addClass('service-selected');
			serviceItem.find('.select-service').addClass('service-selected').text('Selected');
			selectedServices[service] = true;
		});
		callback(serviceData)
	}

	function isSelectionRestricted(selectedKeys) {
		var restrictedServices = JSON.parse($('#restricted_bundles_data').attr('data-restricted_services'));
		return restrictedServices.some(restrictedCombo => {
			// Check if every service in the restrictedCombo is in selectedKeys
			// and the length of both arrays is the same (exact match)
			return restrictedCombo.every(service => selectedKeys.includes(service)) &&
				restrictedCombo.length === selectedKeys.length;
		});
	}

	

    // Function to update the price and savings display
    function updateDisplay(serviceData) {

		var selectedKeys = Object.keys(selectedServices);

		// Enable/disable service items based on potential forbidden combinations
		$('.service-item').each(function() {
			var serviceKey = $(this).find('.select-service').data('service');
			var potentialSelection = [...selectedKeys, serviceKey];
			if (!selectedServices.hasOwnProperty(serviceKey) && isSelectionRestricted(potentialSelection)) {
				// Disable if selecting this service would result in a forbidden combination
				$(this).addClass('disabled').find('.select-service').prop('disabled', true);
			} else {
				$(this).removeClass('disabled').find('.select-service').prop('disabled', false);
			}
		});


		var broadbandService = serviceData['broadband'];
		var broadbandPrice;

		// Check if both mobile and broadband are selected
		if (selectedKeys.includes('mobile') && selectedKeys.includes('broadband')) {
			// Use discountPrice for broadband
			broadbandPrice = broadbandService['discountPrice']['value'] / Math.pow(10, broadbandService['discountPrice']['exponent']);
		} else if (selectedKeys.includes('broadband')) {
			// Use regular price for broadband
			broadbandPrice = broadbandService['price']['value'] / Math.pow(10, broadbandService['price']['exponent']);
		}

		// Update the broadband price display if broadband is available
		if (broadbandPrice !== undefined) {
			$('#broadbandPrice').text(`£${broadbandPrice.toFixed(2)}/month`);
		}

        var numberOfServices = Object.keys(selectedServices).length;
        // Calculate the new energy price based on the number of services selected
        var energyService = serviceData['energy'];
        var energyPrice = energyService['price']['value'] / Math.pow(10, energyService['price']['exponent']);

        if(numberOfServices >= 2 && energyService['twoServicePrice']) {
            energyPrice = energyService['twoServicePrice']['value'] / Math.pow(10, energyService['twoServicePrice']['exponent']);
        }
        if(numberOfServices >= 3 && energyService['threeServicePrice']) {
            energyPrice = energyService['threeServicePrice']['value'] / Math.pow(10, energyService['threeServicePrice']['exponent']);
        }

        // Update the energy price display
        $('#energyPrice').text(`£${energyPrice.toFixed(2)}/month`);

        // Update the savings display...
        // Placeholder for savings update logic

        // Update the savings bar display...
        // Placeholder for savings bar update logic
    }


	$('#lead_script').on('click','.select-mobile-tariff', function() {
		var target = $(this).data('tariff_name').toLowerCase().replace('+','');
		console.log(target);
		$('.selected-sims').removeClass('hidden');
		$('.selected-sim-option').addClass('hidden');
		$('#selected-'+target+'-sim').removeClass('hidden');
		$('.select-mobile-tariff').removeClass('tariff-selected');
		$('.select-mobile-tariff').text('Select');
		$(this).addClass('tariff-selected');
		$(this).text('Selected');
		updateMobileBasketSummary();
	});

	function updateMobileBasketSummary() {
		var summaryItemsHtml = '';
		var basketTotal = 0;

		$('.selected-sim-option').not('.hidden').find('.sim-detail').not('.hidden').each(function(index) {
			var tariffId = $(this).data('tariff_name');
			if(isset(tariffId) && tariffId.length == 0){
				var tariffId = $(this).data('tariff_id');
			}
			
			var tariffName = ucfirst(tariffId.toLowerCase().replace(/_/g, ' '));
			var fullPrice = parseFloat($(this).find('input[name="full_price"]').val().replace('£', ''));
			var discountedPrice = $(this).find('input[name="discounted_price"]').val();
			var priceToUse = discountedPrice ? parseFloat(discountedPrice.replace('£', '')) : fullPrice;

			summaryItemsHtml += '<div class="summary-item">' +
									'<span class="item-name">' + tariffName + '</span>' +
									'<span class="item-price">';
			if (index > 0 && discountedPrice) {
				summaryItemsHtml += '<span class="original-price strike-through">£' + fullPrice.toFixed(2) + '</span>' +
									'<span class="discounted-price">£' + priceToUse.toFixed(2) + '</span>';
			} else {
				summaryItemsHtml += '<span class="original-price">£' + fullPrice.toFixed(2) + '</span>';
			}
			summaryItemsHtml += '</span></div>';

			basketTotal += priceToUse;
		});

		$('.summary-items').html(summaryItemsHtml);
		$('.total-price').text('£' + basketTotal.toFixed(2));
	}


	$('#lead_script').on('click', '.add-additional-sim-button', function() {
		// Disable the button temporarily
		$(this).prop('disabled', true);

		var container = $(this).closest('.added-sims');
		var tariffName = container.closest('.selected-sim-option').attr('id').split('-')[1]; // Get the tariff name from the container's ID
		var nextIndex = container.find('.sim-detail:not(.hidden)').length; // Find the index for the next SIM

		if (nextIndex < 4) {
			// Construct the ID for the next SIM detail div
			var nextSimId = '#selected-' + tariffName + '-sim-' + nextIndex;
			var nextSim = $(nextSimId);

			// Make the next SIM detail visible
			nextSim.removeClass('hidden');

			// If the total number of visible SIMs is now 4, hide the add button
			if (nextIndex + 1 >= 4) {
				container.find('.add-extra-sim').addClass('hidden');
			}
		}

		// Re-enable the button
		$(this).prop('disabled', false);
		 // Update the summary
    	updateMobileBasketSummary();
	});

	$('#lead_script').on('click', '.remove-sim-button', function() {
		// Hide the sim-detail div
		$(this).closest('.sim-detail').addClass('hidden');

		var container = $(this).closest('.added-sims');
		var visibleSims = container.find('.sim-detail:not(.hidden)');

		// Check if less than 4 sims are now visible
		if (visibleSims.length < 4) {
			// If add-extra-sim is hidden, show it again
			container.find('.add-extra-sim').removeClass('hidden');
		}
		updateMobileBasketSummary();
	});

	$('#lead_script').on('click', '.toggle-budget-control', function() {
		var simDetailDiv = $(this).closest('.sim-detail');
		var currentBudgetControl = simDetailDiv.find('input[name="budget_control"]').val();

		// Set the corresponding radio button in the modal
		$('#budget-control-modal').find(`input[name="spend_cap"][value="${currentBudgetControl.toLocaleLowerCase()}"]`).prop('checked', true);
		
		// Store the sim-detail div's identifier in the modal for later reference
		$('#budget-control-modal').data('origin', simDetailDiv.attr('id'));

		// Show the modal
		$('#budget-control-modal').show();
	});

	$('#lead_script').on('click', '.mobile-tariffs-modal-container #save-spend-cap', function() {
		var modal = $('#budget-control-modal');
		var selectedValue = modal.find('input[name="spend_cap"]:checked').val();

		// Make sure a selection has been made
		if (!selectedValue) {
			alert('Please choose an option before saving.');
			return;
		}

		// Use the stored data attribute to find the originating .sim-detail div
		var originId = modal.data('origin');
		var simDetailDiv = $('#' + originId);

		// Update the .toggle-budget-control span text
		simDetailDiv.find('.toggle-budget-control').text(selectedValue.charAt(0).toUpperCase() + selectedValue.slice(1));

		// Update the hidden input value
		simDetailDiv.find('input[name="budget_control"]').val(selectedValue);

		// Close the modal
		modal.hide();

	});
	$('#lead_script').on('click','.confirm-sa-lead-transfer',function(){
		var button = $(this);
		button.addClass('hide');
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			quote_id:quote_id
		};
		makeRequest('/quote/add-live-transfer-event', data, function(response) {
			if(isset(response.error)){
				button.removeClass('hide');
				error_message(response.error);
			}
		});
	});


	function toggleMobileTariffAdditionalQuestions() {
        var keepNumberValue = $('input[name="keep_existing_number"]:checked').val();
        var cancelContractValue = $('input[name="cancel_existing_contract"]:checked').val();

        // Hide all additional questions initially
        $('.existing-number-additional-questions').addClass('hidden');
        $('.cancel-existing-contract-radio-buttons').addClass('hidden');
        $('.cancel-existing-contract-additional-questions').addClass('hidden');

        // Conditional checks to toggle the visibility
        if (keepNumberValue === 'yes') {
            $('.existing-number-additional-questions').removeClass('hidden');
            $('.cancel-existing-contract-radio-buttons').addClass('hidden');
            $('.cancel-existing-contract-additional-questions').addClass('hidden');
        } else if (keepNumberValue === 'no') {
            $('.cancel-existing-contract-radio-buttons').removeClass('hidden');
            // Only show the cancel contract additional questions if 'Yes' is selected for canceling the contract
            if (cancelContractValue === 'yes') {
                $('.cancel-existing-contract-additional-questions').removeClass('hidden');
            }
        }
		// if both options are no - enable button
		// if either option is yes - we should validate the inputs before we allow them to continue 
    }

	// Event listener for change on the 'keep existing number' radio buttons
	$('#lead_script').on('change','#number-options-form .existing-number-radio-buttons input[type="radio"]',function(){
        toggleMobileTariffAdditionalQuestions();
    });

    // Event listener for change on the 'cancel existing contract' radio buttons
	$('#lead_script').on('change','#number-options-form .cancel-existing-contract-radio-buttons input[type="radio"]',function(){
        toggleMobileTariffAdditionalQuestions();
    });
	
	function toggleMobilePortSaveButton(enable) {
		 var $saveButton = $('#save-number-options');
        if (enable) {
            $saveButton.prop('disabled', false);
        } else {
            $saveButton.prop('disabled', true);
        }
    }

	function validateMobileNumberForPorting(number) {
        var regex = /^07\d{9}$/; // UK mobile numbers start with 07 and have 11 digits in total
        return regex.test(number);
    }

	$('#lead_script').on('input','#number-options-form input[name="existing_number"]',function(){
		 var numberValid = validateMobileNumberForPorting($(this).val());
		 	toggleMobilePortSaveButton(numberValid);
	});

	$('#lead_script').on('click','#save-number-options',function(e) {
		// stop the button refreshing the page
		e.preventDefault();
    // Disable the save button while processing
		toggleMobilePortSaveButton(false);

		var originDivId = $('#number-options-modal').data('origin');
		var originDiv = $('#' + originDivId);
		var quote_id = $('#lead_script').data('quoteid');
		var keepExistingNumber = $('input[name="keep_existing_number"]:checked').val();
		var cancelExistingContract = $('input[name="cancel_existing_contract"]:checked').val();

		function validateAndProcessCode(type, codeInputId, codeErrorId) {
			var code = $(codeInputId).val();
			if(type == 'stac'){
				var number = $('.cancel-number-details #existing-number').val();
			}
			else{
				var number = $('.keep-number-details #existing-number').val();
			}
			if (code.length >= 6) {
				var data = {
					quote_id,
					type: type,
					code: code,
					number: number
				};
				makeRequest('/quotes/validate/mobile-code', data, function(response) {
					if (response.success && response.valid) {
						var numberValid = validateMobileNumberForPorting(data.number);
						if (numberValid) {
							updateHiddenInputs();
						} else {
							showError(codeErrorId, 'Number is not valid');
						}
					} else {
						showError(codeErrorId, 'Code is not valid');
					}
				});
			} else {
				showError(codeErrorId, 'Code is not valid');
			}
		}

		function showError(errorId, errorMessage) {
			$(errorId).text(errorMessage).removeClass('hidden').addClass('shake');
			toggleMobilePortSaveButton(true); // Re-enable the button for correction
		}

		function updateHiddenInputs() {
			// Update hidden inputs in the origin div
			if(keepExistingNumber == 'yes'){
				var number = $('.keep-number-details #existing-number').val();
			}
			else if(cancelExistingContract == 'yes'){
				var number = $('.cancel-number-details #existing-number').val();
			}
			if(validateMobileNumberForPorting(number)){      
				if(keepExistingNumber == 'yes'){
					originDiv.find('.toggle-number-control').text($('#existing-number').val());
				}
				else if(cancelExistingContract == 'yes'){
					originDiv.find('.toggle-number-control').text('New (STAC)');
				}
				else{
					originDiv.find('.toggle-number-control').text('New');
				}
			}
			else{
				originDiv.find('.toggle-number-control').text('New');
			}
			originDiv.find('input[name="keep_existing_number"]').val(keepExistingNumber);
			originDiv.find('input[name="pac_code"]').val($('#pac-code').val());
			originDiv.find('input[name="cancel_existing_contract"]').val(cancelExistingContract);
			originDiv.find('input[name="stac_code"]').val($('#stac-code').val());
			originDiv.find('input[name="existing_number"]').val(number);

			// Close the modal and re-enable the button
			$('#number-options-modal').hide();
			toggleMobilePortSaveButton(true);
		}

		if (keepExistingNumber === 'yes') {
			validateAndProcessCode('pac', '#pac-code', '#pac-code-error');
		} else if (cancelExistingContract === 'yes') {
			validateAndProcessCode('stac', '#stac-code', '#stac-code-error');
		} else {
			// If both options are 'no', update the inputs directly
			updateHiddenInputs();
		}
	});



	// Event listener to open the number control modal
	$('#lead_script').on('click','.toggle-number-control',function() {
		var simDetailDiv = $(this).closest('.sim-detail');
		
		// we need to grab the existing settings from the hidden inputs. 
		
		// Store the sim-detail div's identifier in the modal for later reference
		$('#number-options-modal').data('origin', simDetailDiv.attr('id'));

		$('#number-options-modal input[name="keep_existing_number"][value="' + simDetailDiv.find('input[name="keep_existing_number"]').val() + '"]').prop('checked', true);
		$('#number-options-modal input[name="pac_code"]').val(simDetailDiv.find('input[name="pac_code"]').val());
		$('#number-options-modal input[name="cancel_existing_contract"][value="' + simDetailDiv.find('input[name="cancel_existing_contract"]').val() + '"]').prop('checked', true);
		$('#number-options-modal input[name="stac_code"]').val(simDetailDiv.find('input[name="stac_code"]').val());
		$('#number-options-modal input[name="existing_number"]').val(simDetailDiv.find('input[name="existing_number"]').val());

		// Show the modal
		$('#number-options-modal').show();

	});


// When the user clicks on <span> (x), close the modal
	$('#lead_script').on('click','.mobile-tariffs-modal-container .close',function(){
		$(this).closest('.modal').hide();	
	});

	// When the user clicks anywhere outside of the modal, close it
	$(window).on('click', function(event) {
		if ($(event.target).is('.modal')) {
			$('.modal').hide();
		}
	});

	// function updateEnergyUsageDisplay(){
		// the two main inputs we care about are...
		// supply type, if it's equal to gas - disable the option for eco7 by adding class option-dsiabled
		// if it's already selected - remove the class option selected and auto select the standard option 
		// do this by checking the associated checkbox and adding the class option-selected
		// if dual is selected and eco7 is enabled we need to remove the class hidden from electricity-eco7-inputs and gas-standard-inputs
		// then add class hidden to electricity-standard-inputs
		// if dual is selected and the meter type is standard remove the class hidden from electricity-standard-inputs and gas-standard-inputs
		// then add class hidden to electricity-eco7-inputs
		// if supply type is gas hide electricity-standard-inputs and electricity-eco7-inputs
		// if supply type is electricity hide gas-standard-inputs - respect eco 7 rules for which inputs should be shown. 

		// if meter_type is toggled we need to update the actual usage fields
		// we need to hide the eco7 inputs electricity-eco7-inputs if meter type is standard and show the electricity-standard-inputs 
		// the inverse if eco7 is selected 

	// }

	function updateEnergyUsageDisplay() {
		var supplyType = $("input[name='supply_type']:checked").val();
		var hasEco7 = $("input[name='meter_type'][value='economy_7']").is(':checked');
		
		// Handle Eco7 options for Gas Supply
		if (supplyType === 'gas') {
			$(".meter-type-section input[name='meter_type'][value='economy_7']").closest('.energy-choice').removeClass('option-selected').addClass('option-disabled');
			$(".meter-type-section input[name='meter_type'][value='standard']").prop('checked', true).closest('.energy-choice').addClass('option-selected');
			$('.electricity-consumption-actual').addClass('hidden');
			$('.gas-consumption-actual').removeClass('hidden');
		} else if(supplyType == 'dual'){
			$('.gas-consumption-actual').removeClass('hidden');
			$('.electricity-consumption-actual').removeClass('hidden');
			$(".meter-type-section input[name='meter_type'][value='economy_7']").closest('.energy-choice').removeClass('option-disabled');
		} else if(supplyType == 'electricity'){
			$('.gas-consumption-actual').addClass('hidden');
			$('.electricity-consumption-actual').removeClass('hidden');
			$(".meter-type-section input[name='meter_type'][value='economy_7']").closest('.energy-choice').removeClass('option-disabled')
		}

		if (supplyType === 'dual' || supplyType === 'electricity') {
			if (hasEco7) {
				$('.electricity-eco7-inputs').removeClass('hidden');
				$('.electricity-standard-inputs').addClass('hidden');
			} else {
				$('.electricity-standard-inputs').removeClass('hidden');
				$('.electricity-eco7-inputs').addClass('hidden');
			}
			
		} 
	}

	
	$('#lead_script').on('click', '.energy-usage-page .simple-usage-choice, .energy-usage-page .energy-choice', function () {
		if ($(this).hasClass('option-disabled')) {
			return false;
		}

		var radioInput = $(this).find('input:radio');
		var section = $(this).closest('.section');
		var classSelector = $(this).hasClass('simple-usage-choice') ? '.simple-usage-choice' : '.energy-choice';

		// Handle the selection
		section.find(classSelector).removeClass('option-selected');
		$(this).addClass('option-selected');
		radioInput.prop("checked", true);

		// Manage additional display logic
		var target = $(this).data('target');
		var type = $(this).data('input_type');
		if (target && type) {
			var wrapper = $('.' + type + '-energy-usage-wrapper');
			$('.usage-type-input-section-wrapper').find('.usage-type-input-section').addClass('hidden');
			wrapper.find('.energy-usage-type-additional-information').addClass('hidden');
			wrapper.find('#' + target).removeClass('hidden');
			wrapper.removeClass('hidden');
		}

		updateEnergyUsageDisplay();

		// Handle specific logic for energy-choice within womp-energy-intro-panel
		if ($(this).closest('.energy-usage-page').hasClass('womp-energy-intro-panel')) {
			var quote_id = $('#lead_script').data('quoteid');
			var field = radioInput.attr('name');
			var value = radioInput.val();
			var data = {
				quote_id: quote_id,
				field: field,
				value: value
			};
			if(field == 'payment_method'){
				if(value == 'prepayment'){
					$('.prepay-warning-box').removeClass('hide');
					$('.hide-section-if-prepay').addClass('hide');
				}	
				else{
					$('.prepay-warning-box').addClass('hide');
					$('.hide-section-if-prepay').removeClass('hide');
				}
			}

			makeRequest('/quote/update-inline-energy-usage', data, function (response) {
				if (response.error) {
					error_message(response.error);
				}
			});
		}
	});


	$('#lead_script').on('click', '#utility_warehouse_energy_current_usage_next',function(e){
		e.preventDefault();
		var next_step = $(this).data('success-step');

		var quote_id = $('#lead_script').data('quoteid');

		var usageDetails = {};
		// Get selected options from radio buttons
		$('.option-selected input[type="radio"]').each(function() {
			var name = $(this).attr('name');
			var value = $(this).val();
			usageDetails[name] = value;
		});

		if ($('.usage-type-input-section').is(':visible')) {
			$('.actual-energy-usage-wrapper:not(.hidden)').find('.usage-input-wrapper input[type="text"]').each(function() {
				var name = $(this).attr('name');
				var value = $(this).val();
				usageDetails[name] = value;
			});
		}

		var energy_supplier = $('.energy-supplier-and-spend-section select[name="energy_supplier"]').val();
		var energy_spend = $('.energy-supplier-and-spend-section input[name="current_energy_spend"]').val();
		usageDetails['energy_supplier'] = energy_supplier;
		usageDetails['energy_spend'] = energy_spend;
		

		var broadband_supplier = $('.broadband-supplier-and-spend-section select[name="broadband_supplier"]').val();
		var broadband_spend = $('.broadband-supplier-and-spend-section input[name="current_broadband_spend"]').val();
		usageDetails['broadband_supplier'] = broadband_supplier;
		usageDetails['broadband_spend'] = broadband_spend;

		var data = {
			usageDetails: usageDetails,
			quote_id: quote_id
		};

		var data = {
			usageDetails: usageDetails,
			quote_id :quote_id
		};

		makeRequest('/quote/utility-warehouse/set-energy-usage', data, function(response) {
			if (response.success) {
				lead_script.handleStep(next_step);
			} else {
				error_message(response.error);
			}
		});
		
	});

	$('#lead_script').on('click','.required_products .missing-product-links',function(){
		var product_selection_name = $(this).data('target_product_selection');
		var current_step = lead_script.step;
		var target_step = current_step.split('.').slice(0, 1).join('') + '.' + product_selection_name + '_tariffs';
		
		lead_script.handleStep(target_step);
	});

	$('#lead_script').on('click','.utility-warehouse-energy-basket-product-section .edit-product-group-on-order',function(){
		var product_selection_name = $(this).data('product_group_name');
		var current_step = lead_script.step;
		var target_step = current_step.split('.').slice(0, 1).join('') + '.' + product_selection_name + '_tariffs';
		lead_script.handleStep(target_step);
	});
	
	$('#lead_script').on('click','.utility-warehouse-energy-basket-product-section .remove-product-group-on-order',function(){
		var current_step = lead_script.step;
		var target_step = current_step.split('.').slice(0, 1).join('') + '.service_selection';
		lead_script.handleStep(target_step);
	});
	$('#lead_script').on('click','#confirm-utility-warehouse-order',function(){
		showLoader();
		var quotes_to_confirm = [];
		$('.basket-products-content').each(function(i,e){
			var checkbox = $(this).find('.toggle-basket-group-switch');
			if(checkbox.length){
				if(checkbox.is(':checked')){
					quotes_to_confirm.push($(this).data('quote_id'));
				}
			}
			else{
				//its not optional if no checkbox
				quotes_to_confirm.push($(this).data('quote_id'));
			}
		});
		
		var keys_to_update  = {};
		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update
		};

		data['reset_confirmed_date'] = $(this).data('reset-confirmed-date');
		
		var api = new apiInterface();
		api.confirmOrders(data, function(response){
			if(response.success){
				window.location.href =  response.redirect_url;
			}
			else{
				hideLoader();
				if(isset(response.errrors)){
					response.errors.each(function(i,e){
						error_message(e);
					});
				}
				else{
					error_message('something went wrong');
				}
			}
		});
		
		return;
	});



});